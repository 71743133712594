.slider {   


    ul li button {
        &::before {
            font-size: 10px;
            color: $light;
        }
    }

    .slick-dots {
        bottom: 5%;
        right: -551px;
    }

    li.slick-active button::before {
        color: $light;

    }

    //show prev button
    button {
        z-index: 1;
    }

    //hidden by default
    .slick-list {
        overflow: visible;
    }

    &__wrapper {
        position: relative;
        padding-right: 1em;
        margin: 2em;

        img {           
            
            @include br-5;
            @include hw-100;
            max-height: 400px;
            object-fit: cover;            
            @include border(3px);
            @include box-shadow;       
            @include box-shadow;   
            @include transition;

            &:hover {
                @include box-shadow;
                transform: scale(1.01);
                border-color: rgba(249, 249, 249, 0.8);

                h5 {
                    color: $dark;
                }
            }
            
            @media screen and (max-width: 768px) {
                margin: 2em;
            }

            @media screen and (max-width: 480px) {
                margin: 0;
            }  
        }

        h5 {
            position: absolute;
            font-size: 1.2em;
            font-weight: bold;            
            
           &.slider__title--box {
                
                bottom: 0;
                left: 2%;
                width: 80%;
                padding: 10px;
                background: rgba(0, 0, 0, 0.5);
                color: #fff;
                font-size: 1.2rem;
                text-align: center;
                z-index: 2;
                opacity: 1;

                &:hover {
                    @include box-shadow;
                    transform: scale(1.01);
                    border-color: rgba(249, 249, 249, 0.8);
                    opacity: 1;
                    z-index: 3;

                }
                

                @media screen and (max-width: 768px) {
                    left: 5%;                    
                    bottom: 15%;
                    font-size: 0.9em;
                    padding: 0.5em;
                }

                @media screen and (max-width: 500px) {
                    left: 5%;
                    
                    bottom: 15%;
                    padding: 0.5em;
                    font-size: 0.9em;
                    padding: 0.5em;
                }

            }

            &.slider__title--name {
                background-color: $secondary;
                text-overflow: clip;
                font-size: 1em;
                font-weight: bold;
                color: $white;
                margin-top: 1em;
                margin-bottom: 1em;
                text-decoration: none;
                bottom: -3%;
                left: 4%;
                z-index: 2;
                
            }


        }

        

        

    }
}

.hoverEffect {
    backface-visibility: hidden;
    border-radius: 4px;    
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px, rgb(0 0 0 / 73%) 0px 16px 10px -10px;
    cursor: pointer;
    display: block;
    position: relative;
    transform: scale(1, 1) translateZ(0px);
    transition-duration: 300ms;
    transition-property: transform, box-shadow;
    transition-timing-function: ease-out;
    -webkit-font-smoothing: subpixel-antialiased;

    &:hover {
        z-index: 2;
        box-shadow: rgb(0 0 0 / 20%) 0px 36px 38px -16px, rgb(0 0 0 / 52%) 0px 20px 22px -5px;
        transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
        transition-duration: 300ms;
        transition-property: transform, box-shadow;
        transition-timing-function: ease-out;

    }
}