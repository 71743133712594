.offcanvas-body {
    background-color: $dark;
    
}

.nav-theme {

    &.offcanvas-body {        
        margin-left: 1em;
    }
    margin-left: 7em;

     a {
        color: #fff;
        text-transform: uppercase;
        margin-right: 30px;

        span {
            text-transform: uppercase;
            letter-spacing: 1.42px;
            position: relative;
    
            &:after {
                content: '';
                height: 2px;
                background: $light;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -6px;
                //opacity to hide the line
                opacity: 0;
                transform-origin: left center;
                transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s;
                transform: scaleX(0);
            }
        }
    
        &:hover {
            span:after {
                transform: scaleX(1);
                opacity: 1;
            }
        }

    }

    
}