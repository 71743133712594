@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  @mixin box-shadow {
    box-shadow: 0px 26px 30px -10px rgba(0, 0, 0, 0.69), 0px 16px 10px -10px rgba(0, 0, 0, 0.73);
  }
  
  @mixin border($size) {
    border: $size solid rgba(249, 249, 249, 0.1);
  }

  @mixin border-hover($size) {
    border: $size solid rgba(249, 249, 249, 0.8);
  }
  
  @mixin transition() {
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s;
  }

  @mixin transition-opacity() {
    transition: all 500ms ease-in-out 0.1s;
  }

  @mixin transition-transform() {
    transition: all 500ms ease-in-out 0.1s;
  }

  @mixin br-5() {
    border-radius: 5px;
  }
  @mixin hw-100() {
    height: 100%;
    width: 100%;
  }
  
  @mixin hover() {
    cursor: pointer;
    // transform: scale(1.05);
    border-color: rgba(249, 249, 249, 0.8);
  }
  
  @mixin container {
    min-height: calc(100vh - $header-height);
    padding: 0 calc(3.5vw + 5px);
    position: relative;
  }
  
  @mixin overlay {
    background-image: radial-gradient(farthest-side at 73% 21%, transparent, rgb(26, 29, 41));
    position: absolute;
    inset: 0px;
  }
  
  @mixin bgImage {
    &:before {
      background: url('../../../public/assets/images/home-background.png') center center / cover no-repeat fixed;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
  
    }
  }
  
  @mixin bgImageLogin {
    &:before {
      background-position: top;
      background: url('../../../public/assets/images/login-background.jpg') center center / cover no-repeat fixed;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
  
    }
  }
  
  @mixin subtitle {
    
      padding: 0 calc(1.5vw + 1px);
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      color: $text-white;
      margin-bottom: 1rem;
  
  }