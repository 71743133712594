// Theme
$body-bg: #090b13;
$body-color: #fff;
$body-font-family: 'Poppins', sans-serif;
$body-font-size: 1.2em;
$body-font-weight: 400;
$body-font-style: normal;
$body-font-line-height: 1.5;
$body-font-letter-spacing: 0;
$body-font-family-sans-serif: 'Poppins', sans-serif;

$primary: #048A81;
$secondary: #090b13;
$danger: #990033;
$slate: #6F7D8C;
$slate-light: #8C99A8;
$slate-dark: #566D7E;
$warning: #E3B505;
$info: #007991;
$green: #9BC53D;
$dark: #090b13;
$light: #F1F7ED;
$transparent: rgba(255, 255, 255, 0);
$white: #fff;
$black: #000;
$bg-overlay: rgba(15, 33, 45, 0.461);
$bg-overlay-light: rgba(165, 174, 179, 0.238);


.bg-overlay {
  background-color: $bg-overlay;
}

.bg-overlay-light {
  background-color: $bg-overlay-light;
}



$theme-colors: (
    "primary": $primary,
    "danger": $danger,
    "secondary": $slate,
    "info": $info,
    "success": $green,
    "warning": $warning,
    "dark": $dark,
);

@import '~react-modal-video/scss/modal-video.scss';
