.details {
    &__container {
        min-height: calc(100vh - $header-height);
        padding: 0 calc(3.5vw + 5px);
        position: relative;

    }

    &__image-title {
        img {
            max-height: 300px;
        }
    }

    &__image-bg {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        opacity: 0.8;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

        }

    }

    &__image-overlay {
        @include overlay;
    }

    &__info {
        &--item {
            margin-bottom: 1.5rem;
        }

        &--text {
            font-size: 20px;
            letter-spacing: -.1px;
            line-height: 1.6;
        }
    }

    .consensus {
        width: 68px;
        height: 68px;
        display: inline-block;
        transition: transform .2s;
        transform: scale(1);


        &__outer_ring {
            display: inline-block;
            width: 68px;
            height: 68px;
            border-radius: 50%;
            padding: 4px;
            background-color: #081c22;
        }

        &__user_score_chart {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 100%;
            text-align: center;
            
        }

        &__percent {

            width: 100%;
            height: 100%;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;

        }

    }

    &__controls, button {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 1rem;
        border-radius: 4px;
        font-size: 15px;        
        align-items: center;
        height: 56px;
        border: none;
        padding: 0 24px;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 1rem;
        margin-left: -1rem;
        

        &--playButton {
            background-color: rgba(249, 249, 249);
            margin-right: 22px;
            &:hover {
                background: rgb(198,198,198);
            }

        }

        &--trailerButton {
            background: rgba(0,0,0,0.3);
            width: 180px;
            margin-right: 22px;
            border: 1px solid rgb(249, 249, 249) !important;
            color: rgb(249, 249, 249);
            &:hover {
                background: rgba(0,0,0,0.65);                
            }

        }

        &--addButton {
            margin-right: 13px;
            width: 50px !important;
            height: 50px !important;
            border-radius: 50% !important;
            border: 1px solid rgb(249, 249, 249) !important;
            
            position: relative;
            background-color: rgba(0, 0, 0, 0.237);

            
            img {
                height: 40px;
                position: absolute;
                top:4px;
                left:4px;
                right: 0;
                bottom: 0;                
                

            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.7);
            }

        }

        &--groupWatchButton {
            margin-right: 22px;
            width: 50px !important;
            height: 50px !important;
            border-radius: 50% !important;
            border: 1px solid rgb(249, 249, 249) !important;
            
            position: relative;
            background-color: rgba(0, 0, 0, 0.237);

            
            img {
                height: 40px;
                position: absolute;
                top:4px;
                left:4px;
                right: 0;
                bottom: 0;                
                

            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.7);
            }

        }

    }
}

.bottom-border {
    margin-right: 40px;
    padding-bottom: 15px;
    position: relative;
    transition: all 350ms ease 0s;

    &:after {
        background-color: rgba(249, 249, 249, 0.306);
        border-radius: 50px 50px 0px 0px;
        content: "";
        height: 3px;
        position: absolute;
        left: 0px;
        bottom: -15px;
        width: 100%;
        opacity: 1;
        transition: opacity 200ms ease 0s;
    }
}




ul.nav.nav-tabs {
    --bs-nav-tabs-border-width: 0px;
    --bs-nav-tabs-border-color: transparent;
    --bs-nav-tabs-border-radius: 0.375rem;
    --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
    --bs-nav-tabs-link-active-color: #8C99A8 !important;
    --bs-nav-tabs-link-active-bg: $transparent;
    --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 rgba(255, 255, 255, 0);
    border-bottom: var($transparent) solid var($transparent);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4;

    & li.nav-item>button.nav-link {
        text-transform: uppercase;
        color: #fff;
        margin-right: 30px;

        &.active {
            color: #8C99A8;
            
        }



        
    }

}