// Main Imports
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Raleway&display=swap');
@import './base/colors'; //override bootstrap main colors
@import './vendor/bootstrap';
@import './base/defaults';
@import './base/mixins'; 
@import './base/heights';


// Modules
@import './module/burgerMenu'; 
@import './module/navigation'; 
@import './module/slider'; 
@import './module/spinner';
@import './module/viewers';


// Layout
@import './layout/home';
@import './layout/details';
@import './layout/person';



